/* Colors */
/* Fonts */
/* Sizes */
/* Transitions */
/* Z-indexes */
/* Colors */
/* Fonts */
/* Sizes */
/* Transitions */
/* Z-indexes */
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: "Open Sans", arial, sans-serif;
}

body {
  margin: 0;
  overflow-x: hidden;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  margin-top: 20px;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: 600;
  margin: 0;
}

p,
ul,
dd {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

strong {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  text-align: left;
}

button,
input,
textarea {
  border: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  color: inherit;
  font: inherit;
}

button {
  cursor: pointer;
  user-select: none;
}
button:disabled {
  cursor: unset;
}

input:disabled,
textarea:disabled,
input:read-only {
  cursor: default;
}

textarea {
  resize: none;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.bg-blue {
  background: #0222ba;
}

.text-red {
  color: #e64c57;
}

.bg-red {
  background: #e64c57;
}

@media (max-width: 991.98px) {
  .desktop {
    display: none !important;
  }
}

.empty {
  color: #a0a0a0;
  font-size: 16px;
  padding: 40px 0;
  text-align: center;
}

/* blink */
.loading {
  animation: blink 1s 0.1s infinite;
}

@keyframes blink {
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0.75;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
div,
p,
span {
  letter-spacing: normal;
}

div,
p,
span {
  letter-spacing: normal;
}

.content-box {
  box-sizing: content-box;
}

.z-999 {
  z-index: 999;
}

.z-300 {
  z-index: 300;
}

.py-2-56 {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}

.stop-scroll-nft-filter {
  height: 100vh;
  overflow: hidden !important;
}

.chart-slider {
  --i: 1;
  transform: translateX(calc(var(--i) * -100%));
  will-change: transform;
  transition: transform 0.3s ease-in-out;
}

.bg-search-gray {
  background: #504e5f;
}

.bg-search-gray:hover {
  background: #696679;
}

.searchBorder {
  background-color: #262433;
  border-width: 0;
}

.leading-9 {
  line-height: 2.25rem;
}

.text-gray {
  color: #c2c2c2;
}

.mb-36 {
  margin-bottom: 9rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.object-cover {
  object-fit: cover;
}

.font-weight-400 {
  font-weight: 400;
}

.searchOpenBorder {
  border-top-color: #504e5f !important;
}

.bg-theme-black-search {
  background: rgba(22, 20, 32, 0.05);
}

.bg-theme-header {
  background: rgba(105, 102, 121, 0.05);
}

.grid-container {
  display: grid;
  gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.proSwap-container {
  gap: 0;
  padding-left: 0;
  padding-right: 0;
}

.grid-container.without-spacing {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.w-favorites {
  width: 453px;
}

.right-favorites {
  right: 412px;
}

.right-1px {
  right: 1px;
}

.favorites {
  border: 1px #3288ec solid;
  background: #1c1926;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
}

.searchInputActive {
  border: 1px solid #3288ec !important;
}

.graph-heigth {
  min-height: 25rem;
}

@media (min-width: 768px) {
  .graph-heigth {
    min-height: 36rem;
  }

  .grid-container {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

.searchBorder {
  border-width: 0.0625rem;
  border-style: solid;
  border-color: #262433;
}

@media (min-width: 1024px) {

  .grid-container {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (min-width: 1280px) {
  .grid-container {
    max-width: 82rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .searchBorder {
    background-color: transparent;
  }

  .xl\:text-86 {
    font-size: 86px;
    line-height: 90px;
  }
}
@media (min-width: 1920px) {
  .xxxl\:max-h-572 {
    max-height: 572px;
  }
}
.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1px {
  right: 1px;
}

.top-0 {
  top: 0;
}

.top-39 {
  top: 39px;
}

.uppercase {
  text-transform: uppercase;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.outline-none {
  outline: none;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-default:hover {
  cursor: default;
}

.bg-noise {
  isolation: isolate;
  position: relative;
}

.collection-desc-item {
  border-right: 1px solid #504e5f;
}

.collection-desc {
  padding: 8px 12px;
  background: #262433;
  border: 1px solid #504e5f;
  border-radius: 8px;
}

.wallet-nft {
  padding: 4px 15px;
  background: #262433;
  border: 1px solid #504e5f;
  border-radius: 8px;
}

.wallet-multiswap {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid #45434f;
}

.favorites {
  border: 1px #3288ec solid;
  background: #1c1926;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
}

@media (min-width: 768px) {
  .md\:collection-desc-item {
    background-color: #262433;
    border: 1px solid #504e5f;
    border-radius: 8px;
    width: 0;
  }

  .collection-desc {
    padding: 0;
    background: transparent;
    border: none;
  }
}
.card-desc-item {
  background-color: #262433;
  border: 1px solid #504e5f;
  border-radius: 8px;
}

.bg-noise.fixed {
  position: fixed;
}

.color-blue {
  color: #3288ec;
}

.bg-noise::after {
  position: absolute;
  content: "";
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/noise.png);
  opacity: 0.07;
  background-repeat: repeat;
}

.collection-logo-border {
  border: 4px solid #30303a;
  border-radius: 50%;
}

.opacity-65 {
  opacity: 0.65;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bg-filter-panel {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
}

.swapChartForm {
  width: 100%;
  height: 100%;
  position: initial;
  z-index: 1;
}

.w-favorites {
  width: 453px;
}

.right-favorites {
  right: 412px;
}

.bg-search-gray {
  background: #504e5f;
}

.bg-search-gray:hover {
  background: #696679;
}

.searchInputActive {
  border: 1px solid #3288ec !important;
}

.searchBorder {
  background-color: #262433;
  border-width: 0;
}

.searchOpenBorder {
  border-top-color: #504e5f !important;
}

@media (max-width: 1024px) {
  .bg-filter-panel {
    backdrop-filter: blur(20px);
  }
}
@media (max-width: 767px) {
  .swapChartForm {
    width: 100%;
    min-height: 60vh;
    height: max-content;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    position: fixed;
    z-index: 21;
  }

  .slideUpIn {
    animation-name: slideUpIn;
    -webkit-animation-name: slideUpIn;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
  }

  .slideUpOut {
    animation-name: slideUpOut;
    -webkit-animation-name: slideUpOut;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
  }
}
@keyframes slideUpIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes slideUpOut {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
.bg-tableSelect {
  background-color: rgba(38, 36, 51, 0.3);
}

.bgCreateNFTForm {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  backdrop-filter: blur(40px);
}

.bg-nft-aggregator {
  background: linear-gradient(271.19deg, rgba(105, 102, 121, 0.2) 35.82%, rgba(105, 102, 121, 0) 135.57%);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
}

.bg-nft-traits-card-mobile {
  background: rgba(105, 102, 121, 0.1);
}

.bg-nft-aggregator-first-card {
  background: #161420;
  box-shadow: inset 0 0 0 1px #3288ec;
  border-radius: 8px;
}

.bg-trade {
  background: linear-gradient(229.89deg, rgba(87, 83, 109, 0.1) -23.86%, rgba(255, 255, 255, 0.1) 104.75%);
  backdrop-filter: blur(100px);
}

.bg-stake {
  backdrop-filter: blur(40px);
}

.bg-auctionForm {
  background: linear-gradient(229.89deg, rgba(87, 83, 109, 0.05) -23.86%, rgba(255, 255, 255, 0.05) 104.75%);
  backdrop-filter: blur(40px);
}

.bg-myNft-panel {
  box-sizing: border-box;
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  border: 0.5px solid #3288ec;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
}

.hover\:bg-gray:hover {
  background: #5a5864;
}
@media (min-width: 768px) {
  .bg-myNft-panel {
    box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.5);
    backdrop-filter: blur(40px);
    border: none;
    background: none;
  }
}
.bg-card {
  background: linear-gradient(153.67deg, rgba(255, 255, 255, 0.06) 48.68%, rgba(255, 255, 255, 0) 137.52%);
  backdrop-filter: blur(62px);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-card {
    background: #292733;
  }
}

.grid-cols-16 {
  grid-template-columns: repeat(16, minmax(0, 1fr));
}

.grid-cols-tokens {
  grid-template-columns: 25px repeat(11, minmax(0, 1fr));
}

.grid-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  grid-template-areas: "content" "footer";
}

#landing.launchpad {
  margin-top: 96px;
}

.grid-area-content {
  grid-area: content;
}

.text-glow {
  text-shadow: 0px 0px 15px rgba(125, 165, 175, 0.73);
}

.text-glow-low {
  text-shadow: 0px 0px 6px rgba(125, 165, 175, 0.73);
}

.hover\:text-glow-low:hover {
  text-shadow: 0px 0px 6px rgba(125, 165, 175, 0.73);
}

.tab-line {
  position: relative;
  width: max-content;
  transition: all 150ms ease-in-out;
}

.tab-line:hover {
  background-color: #262433;
}

.tab-line:hover::after {
  animation: unset;
}

.tab-line::after {
  content: "";
  width: 0%;
  height: 3px;
  margin: auto auto;
  left: 0;
  right: 0;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #3288ec;
  box-shadow: 0px 0px 14px transparent;
  transition: all 0.3s ease;
}

.tab-line.active::after {
  animation: extend-line 0.3s ease forwards;
}

.tab-line.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes extend-line {
  0% {
    box-shadow: 0px 0px 14px transparent;
    width: 0%;
  }
  100% {
    width: 100%;
    box-shadow: 0px 5px 14px #7da5afb3;
  }
}
.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background: #161420;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #262433;
}

.scroll::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}

.mx-0-important {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.radioBtn label {
  display: block;
  cursor: pointer;
}
.radioBtn label > span {
  display: flex;
}
.radioBtn label > span:before {
  content: "";
  display: flex;
  vertical-align: -0.25em;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1.42857px solid white;
  padding-right: 0.5em;
}
.radioBtn label > input:checked + span:before {
  border-color: #3288ec;
  background-image: radial-gradient(circle closest-side, #3288ec 0%, #3288ec 75%, transparent 80%, transparent 100%);
}
.radioBtn label > input:disabled + span {
  color: #696679;
}
.radioBtn label > input:disabled + span:before {
  border-color: #696679;
  background: #696679;
}
.radioBtn label > input {
  display: none;
}

.slippage-group {
  position: relative;
}
.slippage-group input {
  position: absolute;
  width: 0;
  height: 0;
  visibility: collapse;
}
.slippage-group .splippage-containter {
  background: #262433;
}
.slippage-group input[type=radio]:checked + .splippage-containter,
.slippage-group .activePeriod {
  background: #3288ec;
  box-shadow: 0px 0px 20px rgba(50, 136, 236, 0.5);
}

@keyframes higlight {
  0% {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 3px #ffffff, 0px 0px 30px rgba(246, 253, 255, 0.4), inset 0px 0px 15px -8px #ffffff;
  }
}
.shadow-tag {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
}

.grid-rows-fr-max {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
}

.grid-rows-max-fr {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
}

.grid-cols-fr-max {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr max-content;
}

.grid-cols-max-fr {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;
}

.grid-metric {
  padding-top: 0.375rem;
  padding-bottom: 0.625rem;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.divider-metric {
  width: 100%;
  height: 1px;
  background-color: #403e47;
}

html {
  scroll-behavior: smooth;
}

.point-details {
  color: white;
  text-shadow: 0px 0px 0px rgba(125, 165, 175, 0.75);
  transition: all 0.15s ease-in-out;
}

.point-details:hover {
  color: #5aff86;
}

.point-details.active {
  color: #5aff86;
  text-shadow: 0px 0px 15px rgba(125, 165, 175, 0.75);
}

.icon-arrow-down.active {
  transform: rotateZ(180deg);
}

.greenBorder {
  border: 1px solid #5bfc85;
}

.calculator {
  width: 75vw;
  overflow-y: auto;
}
.calculator ul {
  list-style-type: disc;
}
.calculator ul li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.calculator .customCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.calculator .customCheckbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.calculator .customCheckbox + label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #696679;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
}
.calculator .customCheckbox:checked + label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("../images/CheckMark.svg");
}

.border-rio {
  border: 1px solid #504e5f;
}
.border-rio input::placeholder {
  color: #9ca3af;
}

.container-checkbox {
  transition: all 150ms ease-in-out;
  line-height: 24px;
  border-style: solid;
  border-color: white;
  border-width: 0.0625rem;
  border-radius: 0.25rem;
  background-color: transparent;
  position: relative;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  cursor: pointer;
}
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container-checkbox input ~ div {
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.container-checkbox input ~ div div {
  transition: all 150ms ease-in-out;
  width: 0%;
  height: 0%;
  overflow: hidden;
  background-color: white;
  border-radius: 9999px;
  display: grid;
  place-items: center;
}
.container-checkbox:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.container-checkbox input:checked ~ div div {
  width: 140%;
  height: 140%;
}

.shadow-timer {
  box-shadow: 0px 0px 20px rgba(23, 122, 62, 0.5);
}

.box-shadow-lime {
  box-shadow: 0px 0px 20px rgba(23, 122, 62, 0.5);
}

.modalList div {
  border-bottom: 1px solid #33303b;
}
.modalList div:last-of-type {
  border-bottom: none;
}
.shadow-metrics {
  box-shadow: 0px 0px 30px rgba(23, 122, 62, 0.5);
}

.shadow-trade {
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.5);
}

.shadow-popup {
  box-shadow: 0 0 20px 10px #211f2b;
}

.underline {
  text-decoration: underline;
}

.grid-input-b {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-template-columns: 1fr;
  grid-template-areas: "balance" "input" "token";
}

@media (min-width: 768px) {
  .grid-input-b {
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: 1fr max-content;
    grid-template-areas: "input balance" "input token";
  }
}
.area-balance {
  grid-area: balance;
}

.area-input {
  grid-area: input;
}

.area-token {
  grid-area: token;
}

.isolate {
  isolation: isolate;
}

.percent {
  position: relative;
}

.percent::after {
  content: attr(data-percent);
  position: absolute;
  top: 200%;
  left: -250%;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.bg-placeholder-card {
  background: linear-gradient(157.12deg, rgba(255, 255, 255, 0.4) -21.55%, rgba(255, 255, 255, 0.4) -21.53%, rgba(255, 255, 255, 0) 140.6%);
  backdrop-filter: blur(61.4829px);
}

.shadow-bottom-px {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
}

.shadow-inset-input {
  box-shadow: inset 0px 15px 13px rgba(0, 2, 5, 0.22);
}

.border-input {
  border: 1px solid transparent;
  position: relative;
  isolation: isolate;
}

.active-border-input {
  border: 1px solid #3288ec;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.5), inset 0px 15px 13px rgba(0, 2, 5, 0.22);
}

.border-input::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  padding: 1px;
  --color-start: #88a6c7;
  --color-end: #27486d;
  background: linear-gradient(167deg, var(--color-start) 0%, var(--color-end) 18%, rgba(50, 136, 236, 0) 31.1%), linear-gradient(346deg, var(--color-start), var(--color-end) 10%, rgba(36, 155, 255, 0) 26.8%);
  -webkit-mask: linear-gradient(#99adc3 0 0) content-box, linear-gradient(#99adc3 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-brand {
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  border: 0.5px solid #3288ec;
  backdrop-filter: blur(40px);
  border-radius: 1rem;
}

.dexMultiswap::after {
  content: url("/images/polygonMultiswap.svg");
  position: absolute;
  margin-left: -5px;
}

.bg-image-noise {
  position: relative;
}

.bg-image-noise::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/noise.png);
  opacity: 0.07;
  background-repeat: repeat;
}

.transform-x-full {
  transform: translateX(100%);
}

.shadow-button {
  box-shadow: inset 0px 0px 0px rgba(49, 133, 72, 0.25);
  transition: all 150ms ease-in-out;
}
.shadow-button.secondary {
  box-shadow: inset 0 0 0 1px #5bfc85;
}

.shadow-button:not(:disabled):hover {
  box-shadow: inset 0px 10px 7px rgba(49, 133, 72, 0.25), inset 0px 10px 30px #318548;
}

.social-link {
  transition: all 150ms ease-in-out;
}

.social-link:hover {
  color: #c7c7c7;
}

.social-link:active {
  color: #868686;
}
@media (min-width: 768px) {
  .md:bg-contain {
    background-size: contain;
  }
}
.bg-0 {
  background-size: 0%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.text-xxs {
  font-size: 0.625rem;
}

.text-32 {
  font-size: 2rem;
}

.text-15 {
  font-size: 15px;
}

.text-13 {
  font-size: 13px;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.pointer-events-auto {
  pointer-events: auto;
}

.shadow-inset-top {
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
}

.shadow-inset-bottom {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
}

.max-w-568 {
  max-width: 568px;
}

.max-w-580 {
  max-width: 580px;
}

.max-h-220 {
  max-height: 220px;
}

.max-h-432 {
  max-height: 432px;
}

.max-w-343 {
  max-width: 343px;
}

.max-h-450 {
  max-height: 450px;
}

.max-h-490 {
  max-height: 490px;
}

.w-33 {
  width: 33%;
}

.grid-activity {
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr 1.5fr;
}

.grid-offers {
  grid-template-columns: 1.5fr repeat(2, 1fr) 1.2fr repeat(4, 1fr);
}

.grid-offers-made {
  grid-template-columns: 1.5fr repeat(2, 1fr) 1.2fr repeat(3, 1fr);
}

@media (min-width: 768px) {
  .md\:w-210 {
    width: 210px;
  }

  .md\:noBorder {
    box-shadow: none;
  }

  .md\:w-40p {
    width: 40%;
  }
}
.max-w-648 {
  max-width: 648px;
}

.-translate-x-20 {
  transform: translateX(-20%);
}

.translate-x-20 {
  transform: translateX(20%);
}

.-translate-y-50 {
  transform: translateY(-50%);
}

.translate-x-40 {
  transform: translateX(40%);
}

.-translate-x-50 {
  transform: translateX(-50%);
}

.h-15 {
  height: 3.625rem;
}

.h-41 {
  height: 164px;
}

.h-35 {
  height: 140px;
}

.h-17 {
  height: 68px;
}

.w-38 {
  width: 152px;
}

.noTopBorder {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.noBottomBorder {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.collection-tab {
  font-size: 18px;
}

.collection-tab::after {
  content: "";
  display: block;
  margin-top: 4px;
  height: 1px !important;
  width: 100% !important;
  background: #ffffff;
  opacity: 0.12;
}

.tab-line-collection {
  position: relative;
  font-size: 18px;
  transition: all 150ms ease-in-out;
}

.tab-line-collection:hover::after {
  animation: unset;
}

.tab-line-collection::after {
  content: "";
  width: 0%;
  height: 2px;
  margin: auto auto;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  will-change: width, height;
  background-color: #5bfc85;
  box-shadow: 0px 0px 14px transparent;
  transition: all 0.3s ease;
}

.tab-line-collection.active::after {
  animation: line 0.3s ease forwards;
}

.tab-line-collection.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.noTopBorder {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.noBottomBorder {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

@keyframes line {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .md\:w-373 {
    width: 373px;
    max-width: 373px;
  }

  .md\:noBorder {
    box-shadow: none;
  }
}
.slippage {
  box-shadow: inset 0px 0px 0px 0.0625rem #504e5f;
}
.slippage.selected {
  box-shadow: inset 0px 0px 0px 0.0625rem #3288ec, 0px 0px 20px rgba(50, 136, 236, 0.5);
}

.roiInput {
  box-shadow: 0 0 0 1px #504e5f;
  box-sizing: border-box;
}

.roiInput:hover {
  box-shadow: 0 0 0 1px #948db8;
}

.roiInput:focus {
  box-shadow: 0 0 0 2px #6199ff;
}

.shadow-tooltip {
  box-shadow: 0px 2px 6px rgba(28, 25, 38, 0.7);
}

.overflow-modal {
  max-height: 90vh;
  overflow-y: scroll;
}

.overflow-modal::-webkit-scrollbar {
  width: 0px;
}

input:disabled {
  color: white;
}

.expand-input {
  max-height: 5.5rem;
}

.expand-input-active {
  max-height: 17.5rem;
}

.calculator {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  grid-gap: 0;
}

.calculator-body {
  border-width: 0.0625rem;
  border-style: solid;
}

.calculator-content {
  overflow-y: scroll;
}

@media (max-height: 980px) {
  .calculator-content {
    max-height: 90%;
  }

  .calculator-body {
    max-height: 85vh;
  }
}
@media (max-width: 1024px) {
  .calculator-body {
    max-width: 22rem;
  }
}
@media (max-width: 480px) {
  .calculator {
    width: 100vw;
    padding: 0;
    height: 100vh;
  }

  .calculator-wrapper {
    max-width: 100vw;
    background-color: #161420;
    margin: auto 0;
  }

  .calculator-body {
    border-width: 0px;
  }
}

.link-tab {
  margin-bottom: -1px;
}

@media (max-width: 768px) {
  .expand-input {
    max-height: 4.75rem;
  }
}
.link-tab {
  margin-bottom: -1px;
}

.terra-finder-link {
  color: #5aff86;
  font-size: 14px;
  border-bottom: 1px solid rgba(91, 252, 133, 0.3);
}

.modal-result-row {
  border-bottom: 1px solid #33303b;
}

.modal-border-top {
  border-top: 1px solid #33303b;
}

.pool-card-bg {
  background-color: rgba(22, 20, 32, 0.05);
}

.earn-card-shadow {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
}

.earn-card-content-frame {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.earn-card-details {
  background: rgba(105, 102, 121, 0.05);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.05);
}

.analytics-card-mobile-bg {
  background: rgba(105, 102, 121, 0.05);
}

@media (min-width: 768px) {
  .analytics-card-mobile-bg {
    background: transparent;
  }
}
.error-message {
  color: #fc5b5b;
  max-width: 24rem;
}

.liquidity-input-active {
  background-color: #3288ec;
  box-shadow: 0px 0px 20px rgba(50, 136, 236, 0.5);
}

.slider {
  z-index: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 2.7rem;
  background: transparent;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider-active {
  height: 0.33rem;
}

.slider-inactive {
  height: 0.22rem;
  background: #696679;
}

.slider::-webkit-slider-thumb {
  z-index: 20;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 2.7rem;
  height: 2.7rem;
  padding: 20px;
  border: 0;
  cursor: pointer;
}

.rocket-thumb {
  width: 2.7rem;
  height: 2.7rem;
}

.main-tone,
.second-tone {
  transition: all 150ms ease-in-out;
}

.twitter .main-tone {
  fill: #44a5ff;
}

.twitter .second-tone,
.discord .second-tone,
.telegram .second-tone,
.youtube .second-tone,
.facebook .second-tone,
.github {
  fill-opacity: 0.2;
}

.twitter:hover .main-tone,
.discord:hover .main-tone,
.telegram:hover .main-tone,
.youtube:hover .main-tone,
.facebook:hover .main-tone,
.github:hover {
  fill: white;
}

.twitter:hover .second-tone,
.discord:hover .second-tone,
.telegram:hover .second-tone,
.youtube:hover .second-tone,
.facebook:hover .second-tone,
.github:hover {
  fill-opacity: 1;
}

.telegram .main-tone {
  fill: #44c7ff;
}

.discord .main-tone {
  fill: #8e9efe;
}

.github .main-tone {
  fill: rgba(142, 158, 254, 0.2);
}

.medium .main-tone {
  fill: #fcfcfc;
}

.medium .second-tone {
  fill-opacity: 0.15;
}

.medium:hover .main-tone {
  fill: #161420;
}

.medium:hover .second-tone {
  fill-opacity: 1;
}

.website .main-tone {
  fill: #5bfc85;
}

.website .second-tone {
  fill-opacity: 0.1;
}

.website:hover .main-tone {
  fill: #319e4e;
}

.website:hover .second-tone {
  fill-opacity: 1;
}

.youtube .main-tone {
  fill: #ff0000;
}

.facebook .main-tone {
  color: #1877f2;
}

.launch-card:hover {
  box-shadow: 0 0 0 1px #5bfc85, 0px 0px 40px rgba(23, 122, 62, 0.7);
}

.launch-card-mask {
  background: linear-gradient(96.11deg, rgba(255, 255, 255, 0.06) 10.28%, rgba(255, 255, 255, 0) 13.51%), linear-gradient(263.69deg, rgba(0, 0, 0, 0.08) 10.52%, rgba(0, 0, 0, 0) 13.03%), linear-gradient(145.26deg, rgba(78, 76, 92, 0.6) 29.8%, rgba(57, 55, 69, 0.6) 77.45%), linear-gradient(180deg, rgba(28, 28, 28, 0.6) 0%, rgba(0, 0, 0, 0.6) 91.53%);
  border-radius: 38px;
}

.launch-card-height {
  min-height: 556px;
}

.min-h-160px {
  min-height: 160px;
}

.min-h-279px {
  min-height: 279px;
}

.nft-select {
  color: white !important;
}
.nft-select > div {
  background: #45434f;
  border: none;
}
.nft-select div:focus-visible,
.nft-select div:active,
.nft-select div:focus {
  border: none;
  outline: none;
}

.nft-select:focus-visible,
.nft-select:active,
.nft-select:focus {
  border: none;
  outline: none;
}

.dropdown {
  color: white !important;
}
.dropdown div:focus-visible,
.dropdown div:active,
.dropdown div:focus {
  border: none;
  outline: none;
}

.dropdown:focus-visible,
.dropdown:active,
.dropdown:focus {
  border: none;
  outline: none;
}

@media (max-width: 767px) {
  .nft-select > div {
    width: 100%;
  }

  .dropdown > div {
    width: 100%;
  }
}
.nftCard {
  transition: 200ms all ease;
  border-radius: 38px;
}

.nftCard:hover {
  box-shadow: 0 0 0 1px #5bfc85;
  filter: drop-shadow(0px 0px 40px rgba(23, 122, 62, 0.7));
}

.nft-card-info-br {
  border-radius: 0px 0px 38px 38px;
}

.nft-card-icon-br {
  border-radius: 38px 38px 0px 0px;
}

.nftFilterPanel {
  width: 352px;
  min-width: 352px;
  box-sizing: border-box;
  z-index: 10;
  position: sticky;
  top: 72px;
  flex-shrink: 0;
  align-self: flex-start;
  height: calc(100vh - 72px);
  overflow-y: auto;
}
.nftFilterPanel .filterCard {
  overflow-y: auto;
  justify-content: start;
}
.nftFilterPanel ::-webkit-scrollbar-thumb {
  background: rgba(22, 20, 32, var(--bg-opacity));
}

.self-end {
  align-self: flex-end;
}

@media (max-width: 1024px) {
  .nftFilterPanel {
    width: auto;
    min-width: auto;
    box-sizing: border-box;
    z-index: 10;
    position: initial;
    height: 100%;
    overflow-y: scroll;
  }
}
.openFilter {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 80;
  height: calc(100% - 72px);
  overflow: auto;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.closeFilter {
  transform: translate3d(-100%, 0px, 0px);
  opacity: 0;
}

.nftHeader::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.navbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.soon:hover .web-site-link span {
  font-weight: 800;
}
.soon:hover .web-site-link span:after {
  animation: extend-line 0.3s ease forwards;
}

.soon .web-site-link {
  position: relative;
}
.soon .web-site-link span::after {
  content: "";
  width: 0%;
  height: 2px;
  margin: auto auto;
  left: 0;
  right: 0;
  position: absolute;
  bottom: -2px;
  left: 0;
  background-color: currentColor;
  box-shadow: 0px 0px 14px transparent;
  transition: all 0.3s ease;
}

@keyframes extend-line {
  0% {
    box-shadow: 0px 0px 14px transparent;
    width: 0%;
  }
  100% {
    width: 100%;
    box-shadow: 0px 5px 14px currentColor;
  }
}
.table-container {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  border: 0.5px solid #3288ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 1rem;
}

.pair-wrapper {
  background: linear-gradient(151.31deg, #a2a6af 16.63%, #2f2f33 97.04%);
  border-radius: 9999px;
}

.shadow-menu-button-item {
  box-shadow: 0px 0px 20px rgba(50, 136, 236, 0.5);
}

.landingTitle {
  font-size: 32px;
  line-height: 44px;
}

.landingTitle56 {
  font-size: 56px;
  line-height: 38px;
}

.story-button {
  letter-spacing: 0.16em;
  white-space: nowrap;
  text-transform: uppercase;
  text-shadow: 0px 0px 15px rgba(125, 165, 175, 0.73);
}

.main-progress-bar {
  height: 2px;
  background: #696679;
}

.usd::after {
  content: "USD";
  top: 2px;
  right: 0.5em;
  position: absolute;
}

.hide-end {
  position: relative;
  overflow: hidden;
}

.hide-end::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 0%, #262433 100%);
}

.place-items-center {
  place-items: center;
}

.hide-end-input {
  position: relative;
  overflow: hidden;
}

.hide-end-input::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 100%;
  background-image: linear-gradient(90deg, transparent 0%, #161420 100%);
}

.fill-action {
  transform: rotateX(0deg);
}

.fill-action::after {
  content: "";
  animation-name: w-fill;
  animation-duration: inherit;
  animation-iteration-count: inherit;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  background-color: #5bfc85;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes w-fill {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.transition-fast-start {
  transition-timing-function: cubic-bezier(0, 1.14, 0, 0.97);
}

.hover\:search-hover:hover {
  background-color: rgba(28, 25, 38, 0.3);
}

.hover\:bg-switch:hover {
  background-color: #504e5f;
}

.hover\:bg-timeframe:hover {
  background-color: #45434f;
}

.grid-transaction {
  --i: 4;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 12.5rem repeat(var(--i), 1fr);
}

@media (min-width: 1280px) {
  .grid-transaction {
    --i: 5;
    grid-template-columns: 12.5rem repeat(var(--i), 1fr);
    grid-column-gap: 10px;
  }
}
.grid-favorites {
  display: grid;
  grid-template-columns: 32px repeat(7, 1fr);
}

.grid-search {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
}

.grid-searchMobile {
  display: grid;
  grid-template-columns: 57px repeat(4, 1fr);
}

.translate-y-0 {
  transform: translateY(0);
}

.-translate-y-full {
  transform: translateY(-100%);
}

.translate-y-full {
  transform: translateY(100%);
}

.-translate-50-50 {
  transform: translate(-50%, 50%);
}

.text-glow-green {
  text-shadow: 0px 0px 15px #5bfc85;
}

.bg-brand {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  border: 0.5px solid #3288ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 1rem;
}

.searchInputActive {
  border: 1px solid #3288ec !important;
}

.bg-search-gray:hover {
  background: #696679;
}

@media (min-width: 768px) {
  .searchBorder {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #262433;
  }
}
@media (min-width: 1280px) {
  .searchBorder {
    background-color: transparent;
  }
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inputNew {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}
.inputNew input:focus::-webkit-input-placeholder {
  color: transparent;
}
.inputNew input:focus:-moz-placeholder {
  color: transparent;
}
.inputNew input:focus::-moz-placeholder {
  color: transparent;
}
.inputNew input:focus:-ms-input-placeholder {
  color: transparent;
}

.inputNew input:hover {
  border-color: #948db8;
}

.hover\:search-hover:hover {
  background-color: rgba(28, 25, 38, 0.3);
}

.inputNew input:focus {
  border-color: #0b76ef;
}

.nft-details-image {
  max-width: 30rem;
}

.border-blue {
  border: solid #3288ec;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 20px rgba(0, 42, 91, 0.5);
  border-radius: 8px 8px 8px 8px;
}

.bg-nft-menu {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
  border: 1px solid #3288ec;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.bg-nft-item {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
}

.bg-nft-item-content {
  background: rgba(105, 102, 121, 0.05);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
}

.IDOsDetailsPanel {
  background: rgba(22, 20, 32, 0.05);
  box-shadow: 0px 4px 30px rgba(37, 105, 185, 0.25);
  backdrop-filter: blur(40px);
  border-radius: 16px;
}

.IDOsPercentBg {
  background: rgba(91, 252, 133, 0.2);
}

.IDOsStatusBr {
  border-radius: 0px 0px 16px 16px;
}

.limit-grid {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  gap: 1rem;
}

@media (min-width: 1024px) {
  .limit-grid {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:text-2xlplus {
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:w-20-p {
    width: 20%;
  }

  .lg\:w-1-4 {
    width: 25%;
  }

  .lg\:w-58-p {
    width: 58.333333%;
  }

  .lg\:text-80 {
    font-size: 80px;
    line-height: 90px;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:h-12 {
    width: 3rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:-bottom-52 {
    bottom: -13rem !important;
  }

  .lg\:-right-12 {
    right: -3rem !important;
  }

  .lg\:mb-500 {
    margin-bottom: 500px !important;
  }
}
.text-15px {
  font-size: 0.938rem !important;
  line-height: 1.25rem !important;
}

.grid-chart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
}

.countdow-border-gray {
  border: 1px solid #504e5f;
}

@media (max-width: 480px) {
  .mb-20-important {
    margin-bottom: 78px !important;
  }
}
.upload-input-border {
  border: 1px dashed #9b96b4;
  box-sizing: border-box;
  border-radius: 4px;
}

.upload-input-active {
  background: rgba(50, 136, 236, 0.07);
}

.red-button {
  background: rgba(252, 91, 91, 0.2);
  border-radius: 8px;
  color: #fc5b5b;
  box-shadow: inset 0px 0px 0px rgba(252, 91, 91, 0.25);
  transition: all 150ms ease-in-out;
}

.red-button:hover {
  background: #fc5b5b;
  color: #fff;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.scrollbar-tune::-webkit-scrollbar {
  width: 2px;
}

.scrollbar-tune::-webkit-scrollbar-track {
  background-color: #262433;
}

.scrollbar-tune::-webkit-scrollbar-thumb {
  background: #504e5f;
}

.scrollbar-tune::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}

.scrollbar-tune-horisontal::-webkit-scrollbar {
  height: 2px;
}

.scrollbar-tune-horisontal::-webkit-scrollbar-track {
  background-color: #1c1926;
}

.scrollbar-tune-horisontal::-webkit-scrollbar-thumb {
  background: #504e5f;
}

.scrollbar-tune-horisontal::-webkit-scrollbar-corner {
  width: 0;
  height: 0;
}

.best-route-price-border {
  border-radius: 12px 12px 12px 0px;
}

.shadow-swapper-fill {
  box-shadow: 8px 0px 0 0px #504e5f, -8px 0px 0 0px #504e5f;
}

.new-route-border {
  border: 1px solid #504e5f;
  border-radius: 4px;
}

.-scale-y-1 {
  transform: scaleY(-1);
}

.multiswap-swappers-info {
  position: relative;
}

.multiswap-swappers-info::after {
  content: "";
  display: block;
  height: 7px;
  width: 7px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(50%) rotate(45deg);
  background-color: #504e5f;
}

.rounded {
  border-radius: 0.25rem;
}

.ido-cols-stats {
  grid-template-columns: 1.7fr repeat(5, 1fr) repeat(3, 0.7fr);
}

.text-overflow-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.chart-slider {
  --i: 0;
  transform: translateX(calc(var(--i) * -100%));
  will-change: transform;
  transition: transform 0.3s ease-in-out;
}

.fade-left,
.fade-right {
  isolation: isolate;
}

.fade-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, rgba(28, 25, 38, 0.0001), currentcolor);
  height: 100%;
  width: 2ch;
  z-index: 1;
}

.fade-right::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(to right, rgba(28, 25, 38, 0.0001), currentcolor);
  height: 100%;
  width: 2ch;
  z-index: 1;
}

.switch {
  --animation-length: 0.3s;
  width: 2.5rem;
  padding: 0.175rem;
  border-radius: 50%;
  position: relative;
  isolation: isolate;
}
.switch input {
  display: none;
}
.switch .switch-thumb {
  height: 1rem;
  min-width: 1rem;
  width: 1rem;
  will-change: width;
  transition: width var(--animation-length) ease-in-out;
  position: relative;
}
.switch .switch-thumb::after {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  border-radius: 50%;
}
.switch input:checked ~ .switch-thumb {
  width: 100%;
}
.switch .switch-body {
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 99rem;
  background-color: transparent;
  border: 1px solid white;
  will-change: width;
  transition: background-color var(--animation-length) ease-in-out;
}
.switch input:checked ~ .switch-body {
  background-color: #3288ec;
  border: 1px solid #3288ec;
}

.best-route-price-border {
  border-radius: 12px 12px 12px 0px;
}

.text-pairNumber {
  color: #9b96b4;
}

.running-line {
  height: 100%;
}
.running-line > :nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 2px;
  transform: translate(-50%);
  left: 50%;
  animation: ticker 20s linear infinite;
}
.running-line > :nth-child(1):hover {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translate(0);
    left: 100%;
  }
  100% {
    transform: translate(-100%);
    left: 0%;
  }
}
.shadow-swapper-fill {
  box-shadow: 8px 0px 0 0px #504e5f, -8px 0px 0 0px #504e5f;
}

.new-route-border {
  border: 1px solid #504e5f;
  border-radius: 4px;
}

.grid-search {
  display: grid;
  grid-template-columns: 57px repeat(10, 1fr);
  grid-template-columns: repeat(14, 1fr);
}

.grid-searchMobile {
  display: grid;
  grid-template-columns: 57px repeat(4, 1fr);
}

.-scale-y-1 {
  transform: scaleY(-1);
}

.word-break {
  word-break: break-word;
}

[data-is-drawer] {
  --transform-close: translateY(100%);
  --transform-open: translateY(0%);
  will-change: transform, background-color;
  transform: var(--transform-close);
}

[data-is-drawer][open] {
  animation: open-drawer var(--duration, 400ms) ease-in-out forwards;
}

[data-is-drawer][closing]:not([open]) {
  animation: close-drawer var(--duration, 400ms) ease-in-out forwards;
}

@keyframes open-drawer {
  0% {
    transform: var(--transform-close);
  }
  100% {
    transform: var(--transform-open);
  }
}
@keyframes close-drawer {
  0% {
    transform: var(--transform-open);
  }
  100% {
    transform: var(--transform-close);
  }
}
[data-is-drawer][data-variant=bottom] {
  --transform-close: translateY(100%);
  --transform-open: translateY(0%);
}

[data-is-drawer][data-variant=right] {
  --transform-close: translateX(100%);
  --transform-open: translateX(0%);
}

[data-is-drawer][data-variant=left] {
  --transform-close: translateX(-100%);
  --transform-open: translateX(0%);
}

[data-is-drawer][data-variant=top] {
  --transform-close: translateY(-100%);
  --transform-open: translateY(0%);
}

.child-pointer-events-all:first-child {
  pointer-events: all;
}

.fade-left,
.fade-right {
  isolation: isolate;
}

.fade-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, currentColor 5%, rgba(28, 25, 38, 0) 100%);
  height: 100%;
  width: 2ch;
  z-index: 1;
}

.fade-right::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(270deg, currentColor 5%, rgba(28, 25, 38, 0) 100%);
  height: 100%;
  width: 2ch;
  z-index: 1;
}

.switch {
  --animation-length: 0.3s;
  width: 2.5rem;
  padding: 0.175rem;
  border-radius: 50%;
  position: relative;
  isolation: isolate;
}
.switch input {
  display: none;
}
.switch .switch-thumb {
  height: 1rem;
  min-width: 1rem;
  width: 1rem;
  will-change: width;
  transition: width var(--animation-length) ease-in-out;
  position: relative;
}
.switch .switch-thumb::after {
  cursor: pointer;
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
  transition: background-color var(--animation-length) ease-in-out;
  border-radius: 50%;
}
.switch input:checked ~ .switch-thumb {
  width: 100%;
  cursor: pointer;
}
.switch .switch-body {
  cursor: pointer;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 99rem;
  background-color: transparent;
  border: 1px solid white;
  will-change: width;
  transition: background-color var(--animation-length) ease-in-out, border-color var(--animation-length) ease-in-out;
}
.switch input:checked ~ .switch-body {
  background-color: #3288ec;
  border: 1px solid #3288ec;
}
.switch input:disabled ~ .switch-thumb::after {
  background-color: #9b96b4;
}
.switch input:disabled ~ .switch-body {
  border-color: #696679;
}
.switch input:checked:disabled ~ .switch-thumb::after {
  background-color: #9b96b4;
}
.switch input:checked:disabled ~ .switch-body {
  border-color: #696679;
  background-color: #696679;
}
.switch input:disabled ~ .switch-thumb,
.switch input:disabled ~ .switch-body,
.switch input:disabled ~ .switch-thumb::after {
  cursor: default;
}

.running-line {
  height: 100%;
}
.running-line > :nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 2px;
  transform: translate(-50%);
  left: 50%;
  animation: ticker 20s linear infinite;
}
.running-line > :nth-child(1):hover {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translate(0);
    left: 100%;
  }
  100% {
    transform: translate(-100%);
    left: 0;
  }
}
.will-change-width {
  will-change: width;
}

.acardion {
  overflow: hidden;
  height: 0;
  opacity: 0;
  pointer-events: none;
  will-change: height;
}

.acardion[starting-state=open] {
  height: auto;
  opacity: 1;
}

.acardion[closing]:not([open]) {
  opacity: 1;
  animation: colaps var(--duration, 300ms) forwards;
}

.acardion[open]:not([starting-state=open]) {
  opacity: 1;
  pointer-events: all;
  animation: open var(--duration, 300ms) forwards;
}

@keyframes open {
  0% {
    height: 0px;
  }
  100% {
    height: var(--h);
  }
}
@keyframes colaps {
  0% {
    height: var(--h);
  }
  100% {
    height: 0px;
  }
}
.grid-route-preview {
  --min-width: 144px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-width), 1fr));
}

.dropdown-bottom-shadow {
  box-shadow: inset 0px -1px 0px #262433;
}

.border-px-black60 {
  box-shadow: inset 0px 0px 0px 1px #262433;
}

.hover\:bg-black-75:hover {
  background-color: #383546;
}

.hover\:bg-black-80:hover {
  background-color: #262433;
}

.hover\:bg-hover-black:hover {
  background-color: #0f0e16;
}

.hover\:text-white:hover {
  color: #fff;
}

.hover\:bg-action-hover:hover {
  background-color: #5bd07a;
}

.hover\:text-action-hover:hover {
  color: #5bd07a;
}

.hover\:bg-theme-black:hover {
  background-color: #161420;
}

.hover\:text-theme-black:hover {
  color: #161420;
}

.hover\:text-theme-gray-light:hover {
  color: #abaaae;
}

.hover\:text-theme-dark-blue:hover {
  color: #3462af;
}

.hover\:text-theme-blue:hover {
  color: #3288ec;
}

.hover\:text-theme-lime:hover {
  color: #5aff86;
}

.hover\:bg-theme-search-hover:hover {
  background-color: #343143;
}

.hover\:opacity-60:hover {
  opacity: 0.6;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.active\:text-action-active:active {
  color: #3dc261;
}

.active\:opacity-60:active {
  opacity: 0.6;
}
.group:hover .group-hover\:bg-black-75 {
  background-color: #383546;
}
.group:hover .group-hover\:bg-black-80 {
  background-color: #262433;
}
.group:hover .group-hover\:text-white {
  color: #fff;
}
.group:hover .group-hover\:text-action-hover {
  color: #5bd07a;
}
.group:hover .group-hover\:text-theme-blue {
  color: #3288ec;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.children\:m-1 > * {
  margin: 0.25rem;
}

.p-1 {
  padding: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.-m-1 {
  margin: -0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.gap-x-1 {
  grid-column-gap: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mb-2 {
  margin-bottom: -0.5rem;
}

.gap-x-2 {
  grid-column-gap: 0.5rem;
}

.gap-y-2 {
  grid-row-gap: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.gap-x-3 {
  grid-column-gap: 0.75rem;
}

.gap-y-3 {
  grid-row-gap: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.gap-x-4 {
  grid-column-gap: 1rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.gap-x-5 {
  grid-column-gap: 1.25rem;
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.gap-x-6 {
  grid-column-gap: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.gap-y-8 {
  grid-row-gap: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.gap-x-10 {
  grid-column-gap: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.gap-x-12 {
  grid-column-gap: 3rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.pt-13 {
  padding-top: 3.25rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.mt-16 {
  margin-top: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.mt-24 {
  margin-top: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.gap-y-0 {
  grid-row-gap: 0;
}

.p-0 {
  padding: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.m-0 {
  margin: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mx-ch {
  margin-left: 1ch;
  margin-right: 1ch;
}

.ml-ch {
  margin-left: 1ch;
}

.mr-ch {
  margin-right: 1ch;
}

.ml-0-5-ch {
  margin-left: 0.5ch;
}

.mx-0-1-ch {
  margin-left: 0.1ch;
  margin-right: 0.1ch;
}

.mr-0-1-ch {
  margin-right: 0.1ch;
}

.p-0-5 {
  padding: 0.125rem;
}

.py-0-5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.mx-0-5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.ml-0-5 {
  margin-left: 0.125rem;
}

.mt-0-5 {
  margin-top: 0.125rem;
}

.mr-0-5 {
  margin-right: 0.125rem;
}

.p-px {
  padding: 0.0625rem;
}

.-ml-px {
  margin-left: -0.0625rem;
}

.p-1-5 {
  padding: 0.375rem;
}

.px-1-5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.py-1-5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.m-1-5 {
  margin: 0.375rem;
}

.mx-1-5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.my-1-5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.mr-1-5 {
  margin-right: 0.375rem;
}

.mb-1-5 {
  margin-bottom: 0.375rem;
}

.-m-1-5 {
  margin: -0.375rem;
}

.-mx-1-5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.-my-1-5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.px-2-5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-2-5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.mt-2-5 {
  margin-top: 0.625rem;
}

.mr-2-5 {
  margin-right: 0.625rem;
}

.ml-3-5 {
  margin-left: 0.875rem;
}

.mb-8-5 {
  margin-bottom: 2.125rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.-ml-18 {
  margin-left: -4.5rem;
}

.pt-17 {
  padding-top: 4.25rem;
}

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.pt-15 {
  padding-top: 3.75rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.pt-13 {
  padding-top: 3.25rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.pb-21-5 {
  padding-bottom: 5.375rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.p-13px {
  padding: 13px;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1/span 1;
}
.col-span-1.col-start-3 {
  grid-column-start: 3;
}
.col-span-1.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.col-span-2 {
  grid-column: span 2/span 2;
}
.col-span-2.col-start-3 {
  grid-column-start: 3;
}
.col-span-2.col-start-5 {
  grid-column-start: 5;
}

.col-span-3 {
  grid-column: span 3/span 3;
}
.col-span-3.col-start-3 {
  grid-column-start: 3;
}
.col-span-3.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.col-span-4 {
  grid-column: span 4/span 4;
}
.col-span-4.col-start-3 {
  grid-column-start: 3;
}
.col-span-4.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.col-span-5 {
  grid-column: span 5/span 5;
}
.col-span-5.col-start-3 {
  grid-column-start: 3;
}
.col-span-5.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.col-span-6 {
  grid-column: span 6/span 6;
}
.col-span-6.col-start-3 {
  grid-column-start: 3;
}
.col-span-6.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.col-span-7 {
  grid-column: span 7/span 7;
}
.col-span-7.col-start-3 {
  grid-column-start: 3;
}
.col-span-7.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.col-span-8 {
  grid-column: span 8/span 8;
}
.col-span-8.col-start-3 {
  grid-column-start: 3;
}
.col-span-8.col-start-5 {
  grid-column-start: 5;
}

.col-span-9 {
  grid-column: span 9/span 9;
}
.col-span-9.col-start-3 {
  grid-column-start: 3;
}
.col-span-9.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.col-span-11 {
  grid-column: span 11/span 11;
}
.col-span-11.col-start-3 {
  grid-column-start: 3;
}
.col-span-11.col-start-5 {
  grid-column-start: 5;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-12 {
  grid-column: span 12/span 12;
}
.col-span-12.col-start-3 {
  grid-column-start: 3;
}
.col-span-12.col-start-5 {
  grid-column-start: 5;
}

.col-span-full {
  grid-column: 1/-1;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.text-2xs {
  font-size: 0.625rem;
  line-height: 0.8rem;
}

.text-11px {
  font-size: 0.6875rem;
  line-height: 0.8rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-2sm {
  font-size: 0.8125rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-smbase {
  font-size: 0.9375rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xl-e {
  font-size: 1.25rem;
  line-height: 2rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base-dense {
  font-size: 1rem;
  line-height: 1.25rem;
}

.text-5xl-h {
  font-size: 3rem;
  line-height: 3.25rem;
}

.text-5xl-h-2 {
  font-size: 3rem;
  line-height: 3.75rem;
}

.text-5xl-d {
  font-size: 3.5rem;
  line-height: 3.75rem;
}

.text-4-5xl-d {
  font-size: 2.5rem;
  line-height: 3rem;
}

.text-4-75xl {
  font-size: 2.75rem;
  line-height: 3.75rem;
}

.text-4xl-d {
  font-size: 2.25rem;
  line-height: 2.75rem;
}

.text-3xl-d {
  font-size: 2rem;
  line-height: 2.5rem;
}

.text-2xl-d {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.text-2xl-dense {
  font-size: 1.75rem;
  line-height: 2rem;
}

.text-2-5rem {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.text-4-5rem {
  font-size: 4.5rem;
  line-height: 4.5rem;
}

.text-xl-d {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.text-xl-xd {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.text-xs-d {
  font-size: 0.8125rem;
  line-height: 1.25rem;
}

.text-xss {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-marcellus {
  font-family: Marcellus, serif;
}

.font-poppins {
  font-family: Poppins, sans-serif;
}

.font-centauri {
  font-family: Centauri, sans-serif;
}

.font-cabin {
  font-family: Cabin;
}

.h-1 {
  height: 0.25rem;
}

.w-1 {
  width: 0.25rem;
}

.h-min-1 {
  min-height: 0.25rem;
}

.w-min-1 {
  min-width: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.w-3 {
  width: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.w-4 {
  width: 1rem;
}

.w-max-4 {
  max-width: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.w-5 {
  width: 1.25rem;
}

.h-min-5 {
  min-height: 1.25rem;
}

.w-min-5 {
  min-width: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.w-7 {
  width: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.w-8 {
  width: 2rem;
}

.h-min-8 {
  min-height: 2rem;
}

.w-min-8 {
  min-width: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.w-9 {
  width: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-min-10 {
  min-width: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.w-11 {
  width: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.w-12 {
  width: 3rem;
}

.h-min-12 {
  min-height: 3rem;
}

.h-13 {
  height: 3.25rem;
}

.h-14 {
  height: 3.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-min-14 {
  min-width: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.w-16 {
  width: 4rem;
}

.w-min-16 {
  min-width: 4rem;
}

.h-20 {
  height: 5rem;
}

.w-20 {
  width: 5rem;
}

.w-min-20 {
  min-width: 5rem;
}

.w-max-20 {
  max-width: 5rem;
}

.h-24 {
  height: 6rem;
}

.w-24 {
  width: 6rem;
}

.h-min-24 {
  min-height: 6rem;
}

.w-max-24 {
  max-width: 6rem;
}

.h-28 {
  height: 7rem;
}

.w-28 {
  width: 7rem;
}

.h-32 {
  height: 8rem;
}

.w-32 {
  width: 8rem;
}

.h-min-32 {
  min-height: 8rem;
}

.h-36 {
  height: 9rem;
}

.w-36 {
  width: 9rem;
}

.h-max-36 {
  max-height: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-min-40 {
  min-width: 10rem;
}

.h-44 {
  height: 11rem;
}

.w-44 {
  width: 11rem;
}

.h-max-44 {
  max-height: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.h-56 {
  height: 14rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.h-68 {
  height: 17rem;
}

.h-80 {
  height: 20rem;
}

.h-max-80 {
  max-height: 20rem;
}

.h-min-92 {
  min-height: 23rem;
}

.w-max-96 {
  max-width: 24rem;
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto;
}

.h-7 {
  height: 1.75rem;
}

.w-7 {
  width: 1.75rem;
}

.h-min {
  height: min-content;
}

.h-max {
  height: max-content;
}

.w-max {
  width: max-content;
}

.basis-max {
  flex-basis: max-content;
}

.w-min-mobile {
  min-width: 23.4375rem;
}

.h-px {
  height: 1px;
}

.h-0-px {
  height: 0px;
}

.h-0 {
  height: 0%;
}

.w-0 {
  width: 0%;
}

.w-30-p {
  width: 30%;
}

.w-max-30-p {
  max-width: 30%;
}

.w-40-p {
  width: 40%;
}

.w-50-p {
  width: 50%;
}

.h-max-80-p {
  max-height: 80%;
}

.w-half {
  width: 50%;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-min-full {
  min-width: 100%;
}

.w-max-full {
  max-width: 100%;
}

.w-0-5 {
  width: 0.125rem;
}

.h-1 {
  height: 0.25rem;
}

.w-1 {
  width: 0.25rem;
}

.h-min-1 {
  min-height: 0.25rem;
}

.w-min-1 {
  min-width: 0.25rem;
}

.h-1-5 {
  height: 0.375rem;
}

.w-1-5 {
  width: 0.375rem;
}

.h-10 {
  height: 2.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-min-10 {
  min-width: 2.5rem;
}

.w-10-5 {
  width: 2.75rem;
}

.h-12-5 {
  height: 3.125rem;
}

.w-12-5 {
  width: 3.125rem;
}

.h-15 {
  height: 3.75rem;
}

.w-min-15 {
  min-width: 3.75rem;
}

.h-17 {
  height: 4.25rem;
}

.h-19 {
  height: 4.75rem;
}

.h-20 {
  height: 5rem;
}

.w-20 {
  width: 5rem;
}

.w-min-20 {
  min-width: 5rem;
}

.w-max-20 {
  max-width: 5rem;
}

.w-22 {
  width: 5.5rem;
}

.h-25 {
  height: 6.25rem;
}

.w-25 {
  width: 6.25rem;
}

.h-35 {
  height: 8.75rem;
}

.h-41 {
  height: 10.25rem;
}

.w-46-5 {
  width: 11.625rem;
}

.h-49 {
  height: 12.25rem;
}

.h-57 {
  height: 14.25rem;
}

.basis-100 {
  flex-basis: 25rem;
}

.w-max-sm {
  max-width: 38rem;
}

.w-max-6xl {
  max-width: 64rem;
}

.w-max-27-5 {
  max-width: 27.5rem;
}

.h-max-125 {
  max-height: 31.25rem;
}

.w-min-200 {
  min-width: 50rem;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}

.border-0 {
  border-width: 0rem;
  border-style: solid;
}
.border-0.border-t-0 {
  border-top-width: 0rem;
  border-top-style: solid;
}
.border-0.border-r-0 {
  border-right-width: 0rem;
  border-right-style: solid;
}
.border-0.border-b-px {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
}

.border-t-0 {
  border-top-width: 0rem;
  border-top-style: solid;
}

.border-r-0 {
  border-right-width: 0rem;
  border-right-style: solid;
}

.border-px {
  border-width: 0.0625rem;
  border-style: solid;
}
.border-px.border-t-0 {
  border-top-width: 0rem;
  border-top-style: solid;
}
.border-px.border-r-0 {
  border-right-width: 0rem;
  border-right-style: solid;
}
.border-px.border-b-px {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
}

.border-b-px {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
}

.border-0-5 {
  border-width: 0.125rem;
  border-style: solid;
}
.border-0-5.border-t-0 {
  border-top-width: 0rem;
  border-top-style: solid;
}
.border-0-5.border-r-0 {
  border-right-width: 0rem;
  border-right-style: solid;
}
.border-0-5.border-b-px {
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
}

.rounded-tl-none {
  border-top-left-radius: 0px;
}

.rounded-tr-none {
  border-top-right-radius: 0px;
}

.rounded-br-none {
  border-bottom-right-radius: 0px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-sm.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-sm.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-sm.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-sm.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-sm.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-sm.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-sm.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-sm.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-sm.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-sm.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-base {
  border-radius: 0.25rem;
}
.rounded-base.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-base.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-base.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-base.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-base.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-base.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-base.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-base.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-base.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-base.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}

.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}

.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}

.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}
.rounded-md.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-md.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-md.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-md.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-md.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-md.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-md.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-md.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-md.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-md.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-lg.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-lg.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-lg.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-lg.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-lg.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-lg.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-lg.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-lg.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-lg.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-lg.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-xl.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-xl.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-xl.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-xl.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-xl.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-xl.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-xl.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-xl.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-xl.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-xl.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}
.rounded-2xl.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-2xl.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-2xl.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-2xl.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-2xl.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-2xl.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-2xl.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-2xl.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-2xl.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-2xl.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-3xl.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-3xl.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-3xl.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-3xl.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-3xl.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-3xl.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-3xl.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-3xl.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-3xl.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-3xl.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-4xl {
  border-radius: 1.875rem;
}
.rounded-4xl.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-4xl.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-4xl.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-4xl.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-4xl.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-4xl.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-4xl.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-4xl.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-4xl.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-4xl.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}
.rounded-full.rounded-tl-none {
  border-top-left-radius: 0px;
}
.rounded-full.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-full.rounded-br-none {
  border-bottom-right-radius: 0px;
}
.rounded-full.rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.rounded-full.rounded-tl-base {
  border-top-left-radius: 0.25rem;
}
.rounded-full.rounded-tr-base {
  border-top-right-radius: 0.25rem;
}
.rounded-full.rounded-br-base {
  border-bottom-right-radius: 0.25rem;
}
.rounded-full.rounded-bl-base {
  border-bottom-left-radius: 0.25rem;
}
.rounded-full.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-full.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.absolute {
  position: absolute;
}

.initial {
  position: initial;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.static {
  position: static;
}

.block {
  display: block;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.contents {
  display: contents;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-right {
  justify-content: right;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-block-start {
  align-items: start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow-4 {
  flex-grow: 1;
}

.shrink-4 {
  flex-shrink: 1;
}

.twc-root {
  --bg-opacity: 1;
}

.bg-black {
  background-color: rgba(0, 0, 0, var(--bg-opacity));
}

.text-black {
  color: #000;
}

.bg-black-50 {
  background-color: rgba(105, 102, 121, var(--bg-opacity));
}

.text-black-50 {
  color: #696679;
}

.border-black-50 {
  border-color: #696679;
}

.placeholder-black-50::placeholder {
  color: #696679;
}

.bg-black-60 {
  background-color: rgba(80, 78, 95, var(--bg-opacity));
}

.border-black-60 {
  border-color: #504e5f;
}

.placeholder-black-60::placeholder {
  color: #504e5f;
}

.bg-black-70 {
  background-color: rgba(69, 67, 79, var(--bg-opacity));
}

.border-black-70 {
  border-color: #45434f;
}

.bg-black-75 {
  background-color: rgba(56, 53, 70, var(--bg-opacity));
}

.bg-black-80 {
  background-color: rgba(38, 36, 51, var(--bg-opacity));
}

.border-black-80 {
  border-color: #262433;
}

.bg-black-90 {
  background-color: rgba(28, 25, 38, var(--bg-opacity));
}

.text-black-90 {
  color: #1c1926;
}

.bg-white {
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.text-white {
  color: #fff;
}

.border-white {
  border-color: #fff;
}

.placeholder-white::placeholder {
  color: #fff;
}

.bg-action {
  background-color: rgba(90, 255, 134, var(--bg-opacity));
}

.text-action {
  color: #5aff86;
}

.border-action {
  border-color: #5aff86;
}

.bg-theme-dark-gray {
  background-color: rgba(42, 40, 51, var(--bg-opacity));
}

.bg-theme-black {
  background-color: rgba(22, 20, 32, var(--bg-opacity));
}

.text-theme-black {
  color: #161420;
}

.bg-theme-gray-cold {
  background-color: rgba(38, 36, 51, var(--bg-opacity));
}

.text-theme-gray-light {
  color: #abaaae;
}

.bg-theme-dark-blue {
  background-color: rgba(52, 98, 175, var(--bg-opacity));
}

.bg-theme-blue {
  background-color: rgba(50, 136, 236, var(--bg-opacity));
}

.text-theme-blue {
  color: #3288ec;
}

.border-theme-blue {
  border-color: #3288ec;
}

.border-theme-blue-light {
  border-color: #6c84b2;
}

.placeholder-theme-blue-light::placeholder {
  color: #6c84b2;
}

.bg-theme-lime {
  background-color: rgba(90, 255, 134, var(--bg-opacity));
}

.text-theme-lime {
  color: #5aff86;
}

.border-theme-lime {
  border-color: #5aff86;
}

.text-theme-secondary {
  color: #5c5c5c;
}

.border-theme-letter {
  border-color: #94a6c7;
}

.text-theme-letter-light {
  color: rgba(242, 244, 248, 0.6);
}

.bg-theme-purple {
  background-color: rgba(143, 0, 255, var(--bg-opacity));
}

.bg-theme-orange {
  background-color: rgba(255, 127, 0, var(--bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.text-transparent {
  color: transparent;
}

.text-timer-unit {
  color: #696679;
}

.bg-bright-green {
  background-color: rgba(91, 252, 133, var(--bg-opacity));
}

.text-bright-green {
  color: #5bfc85;
}

.bg-red {
  background-color: rgba(252, 91, 91, var(--bg-opacity));
}

.text-red {
  color: #fc5b5b;
}

.bg-nft-button {
  background-color: rgba(58, 54, 75, var(--bg-opacity));
}

.bg-nft-info-tab {
  background-color: rgba(105, 102, 121, var(--bg-opacity));
}

.text-yellow {
  color: #fcc55b;
}

.border-yellow {
  border-color: #fcc55b;
}

.bg-blue-gray {
  background-color: rgba(36, 33, 48, var(--bg-opacity));
}

.text-header-tab {
  color: #9b96b4;
}

.border-header-tab {
  border-color: #9b96b4;
}

.bg-fade {
  background-color: rgba(7, 10, 19, var(--bg-opacity));
}

.text-inactive-tab {
  color: #7b7792;
}

.box-shadow-right-gray {
  box-shadow: inset -1px 0px 0px #262433;
}

.box-shadow-tokens-drawer {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35);
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.-z-1 {
  z-index: -1;
}

.z-2 {
  z-index: 2;
}

.z-5 {
  z-index: 5;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-full {
  z-index: 9999;
}

.transition-all {
  transition: 150ms all ease;
}

.transition-all.duration-100 {
  transition-duration: 100ms;
}

.duration-100 {
  --duration: 100ms;
}

.transition-all.duration-150 {
  transition-duration: 150ms;
}

.duration-150 {
  --duration: 150ms;
}

.transition-all.duration-300 {
  transition-duration: 300ms;
}

.duration-300 {
  --duration: 300ms;
}

.transition-all.duration-500 {
  transition-duration: 500ms;
}

.duration-500 {
  --duration: 500ms;
}

.transition-all.duration-700 {
  transition-duration: 700ms;
}

.duration-700 {
  --duration: 700ms;
}

.transition-all.duration-1000 {
  transition-duration: 1000ms;
}

.duration-1000 {
  --duration: 1000ms;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.top-full {
  top: 100%;
}

.-right-full {
  right: -100%;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.-top-1-5 {
  top: -0.375rem;
}

.left-2 {
  left: 0.5rem;
}

.top-2 {
  top: 0.5rem;
}

.-right-2 {
  right: -0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.right-4 {
  right: 1rem;
}

.left-4 {
  left: 1rem;
}

.top-4 {
  top: 1rem;
}

.-left-4 {
  left: -1rem;
}

.left-5 {
  left: 1.25rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.-top-8 {
  top: -2rem;
}

.top-14 {
  top: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.top-16 {
  top: 4rem;
}

.-left-16 {
  left: -4rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.right-20 {
  right: 5rem;
}

.-top-20 {
  top: -5rem;
}

.top-24 {
  top: 6rem;
}

.-left-24 {
  left: -6rem;
}

.top-28 {
  top: 7rem;
}

.-top-32 {
  top: -8rem;
}

.-bottom-32 {
  bottom: -8rem;
}

.top-44 {
  top: 11rem;
}

.-top-44 {
  top: -11rem;
}

.left-48 {
  left: 12rem;
}

.top-64 {
  top: 16rem;
}

.-right-80 {
  right: -20rem;
}

.-left-80 {
  left: -20rem;
}

.-top-80 {
  top: -20rem;
}

.-bottom-80 {
  bottom: -20rem;
}

.left-2 {
  left: 0.5rem;
}

.top-2 {
  top: 0.5rem;
}

.-right-2 {
  right: -0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.left-4 {
  left: 1rem;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.-left-4 {
  left: -1rem;
}

.left-5 {
  left: 1.25rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.-top-8 {
  top: -2rem;
}

.top-14 {
  top: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.top-16 {
  top: 4rem;
}

.-left-16 {
  left: -4rem;
}

.-bottom-16 {
  bottom: -4rem;
}

.right-20 {
  right: 5rem;
}

.-top-20 {
  top: -5rem;
}

.top-24 {
  top: 6rem;
}

.-left-24 {
  left: -6rem;
}

.top-28 {
  top: 7rem;
}

.-top-32 {
  top: -8rem;
}

.-bottom-32 {
  bottom: -8rem;
}

.top-44 {
  top: 11rem;
}

.-top-44 {
  top: -11rem;
}

.left-48 {
  left: 12rem;
}

.top-64 {
  top: 16rem;
}

.-left-80 {
  left: -20rem;
}

.-top-80 {
  top: -20rem;
}

.-right-80 {
  right: -20rem;
}

.-bottom-80 {
  bottom: -20rem;
}

.bg-opacity-5 {
  --bg-opacity: 0.05;
}

.bg-opacity-20 {
  --bg-opacity: 0.2;
}

.bg-opacity-30 {
  --bg-opacity: 0.3;
}

.bg-opacity-60 {
  --bg-opacity: 0.6;
}

.bg-opacity-80 {
  --bg-opacity: 0.8;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-100 {
  opacity: 1;
}

@media (min-width: 400px) {

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 425px) {

  .xs\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xs\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .xs\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xs\:block {
    display: block;
  }

  .xs\:flex {
    display: flex;
  }

  .xs\:justify-center {
    justify-content: center;
  }

  .xs\:items-center {
    align-items: center;
  }

  .xs\:flex-col {
    flex-direction: column;
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 640px) {

  .sm\:mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl-dense {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:w-max {
    width: max-content;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-baseline {
    align-items: baseline;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 768px) {

  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:p-2 {
    padding: 0.5rem;
  }

  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:pr-2 {
    padding-right: 0.5rem;
  }

  .md\:ml-2 {
    margin-left: 0.5rem;
  }

  .md\:mt-2 {
    margin-top: 0.5rem;
  }

  .md\:mr-2 {
    margin-right: 0.5rem;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:ml-3 {
    margin-left: 0.75rem;
  }

  .md\:mr-3 {
    margin-right: 0.75rem;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:pb-44 {
    padding-bottom: 11rem;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-12-5 {
    margin-bottom: 3.125rem;
  }

  .md\:mt-15 {
    margin-top: 3.75rem;
  }

  .md\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .md\:col-span-1.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-1.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-1.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .md\:col-span-2.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-2.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-2.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .md\:col-span-3.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-3.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-3.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .md\:col-span-4.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-4.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-4.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .md\:col-span-5.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-5.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-5.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .md\:col-span-6.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-6.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-6.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .md\:col-span-7.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-7.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-7.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .md\:col-span-8.md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-span-8.md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-span-8.md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .md\:text-2sm {
    font-size: 0.8125rem;
    line-height: 1rem;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-base-dense {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .md\:text-3xl-d {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .md\:text-xs-d {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }

  .md\:w-1 {
    width: 0.25rem;
  }

  .md\:w-2 {
    width: 0.5rem;
  }

  .md\:w-3 {
    width: 0.75rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-min-80 {
    min-width: 20rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-max {
    width: max-content;
  }

  .md\:w-max-mobile {
    max-width: 23.4375rem;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:h-max-full {
    max-height: 100%;
  }

  .md\:w-1 {
    width: 0.25rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:h-50 {
    height: 12.5rem;
  }

  .md\:h-min-57 {
    min-height: 14.25rem;
  }

  .md\:h-61-5 {
    height: 15.375rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:border-r-0 {
    border-right-width: 0rem;
    border-right-style: solid;
  }

  .md\:border-px {
    border-width: 0.0625rem;
    border-style: solid;
  }

  .md\:border-b-px {
    border-bottom-width: 0.0625rem;
    border-bottom-style: solid;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0px;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0px;
  }

  .md\:rounded-tl-base {
    border-top-left-radius: 0.25rem;
  }

  .md\:rounded-bl-base {
    border-bottom-left-radius: 0.25rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:block {
    display: block;
  }

  .md\:grid {
    display: grid;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:contents {
    display: contents;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  html {
    --bg-opacity: 1;
  }

  .md\:text-white {
    color: #fff;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:top-9 {
    top: 2.25rem;
  }

  .md\:-bottom-20 {
    bottom: -5rem;
  }

  .md\:top-24 {
    top: 6rem;
  }
}
@media (min-width: 1024px) {

  .lg\:pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:pr-7 {
    padding-right: 1.75rem;
  }

  .lg\:mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:mt-36 {
    margin-top: 9rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-8-5 {
    margin-bottom: 2.125rem;
  }

  .lg\:mb-18 {
    margin-bottom: 4.5rem;
  }

  .lg\:pb-17 {
    padding-bottom: 4.25rem;
  }

  .lg\:pt-15 {
    padding-top: 3.75rem;
  }

  .lg\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .lg\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .lg\:pb-21-5 {
    padding-bottom: 5.375rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .lg\:col-span-1.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-1.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-1.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-1.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-1.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-1.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .lg\:col-span-3.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-3.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-3.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-3.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-3.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-3.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .lg\:col-span-4.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-4.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-4.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-4.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-4.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-4.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .lg\:col-span-5.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-5.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-5.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-5.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-5.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-5.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .lg\:col-span-6.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-6.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-6.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-6.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-6.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-6.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .lg\:col-span-7.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-7.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-7.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-7.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-7.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-7.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .lg\:col-span-8.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-8.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-8.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-8.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-8.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-8.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .lg\:col-span-9.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-9.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-9.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-9.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-9.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-9.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .lg\:col-span-10.lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-span-10.lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-span-10.lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-span-10.lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-span-10.lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-span-10.lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .lg\:text-2sm {
    font-size: 0.8125rem;
    line-height: 1rem;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:text-smbase {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-base-dense {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .lg\:text-5xl-h {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  .lg\:text-5xl-h-2 {
    font-size: 3rem;
    line-height: 3.75rem;
  }

  .lg\:text-3xl-d {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .lg\:text-2xl-dense {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  .lg\:text-xl-d {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .lg\:text-xss {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:w-1 {
    width: 0.25rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-9 {
    height: 2.25rem;
  }

  .lg\:w-9 {
    width: 2.25rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-max {
    height: max-content;
  }

  .lg\:w-max {
    width: max-content;
  }

  .lg\:w-20-p {
    width: 20%;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-1 {
    width: 0.25rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:border-0 {
    border-width: 0rem;
    border-style: solid;
  }

  .lg\:border-t-0 {
    border-top-width: 0rem;
    border-top-style: solid;
  }

  .lg\:border-r-px {
    border-right-width: 0.0625rem;
    border-right-style: solid;
  }

  .lg\:border-b-px {
    border-bottom-width: 0.0625rem;
    border-bottom-style: solid;
  }

  .lg\:border-l-px {
    border-left-width: 0.0625rem;
    border-left-style: solid;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:block {
    display: block;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  html {
    --bg-opacity: 1;
  }

  .lg\:bg-transparent {
    background-color: rgba(0, 0, 0, var(--bg-opacity));
  }

  .lg\:border-header-tab {
    border-color: #9b96b4;
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-inherit {
    text-align: inherit;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:top-22 {
    top: 5.5rem;
  }

  .lg\:top-4 {
    top: 1rem;
  }

  .lg\:left-10 {
    left: 2.5rem;
  }

  .lg\:-right-12 {
    right: -3rem;
  }

  .lg\:-bottom-52 {
    bottom: -13rem;
  }

  .lg\:-bottom-96 {
    bottom: -24rem;
  }
}
@media (min-width: 1120px) {

  .lg2\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 1180px) {

  .lg3\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg3\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg3\:absolute {
    position: absolute;
  }

  .lg3\:block {
    display: block;
  }

  .lg3\:flex {
    display: flex;
  }

  .lg3\:hidden {
    display: none;
  }

  .lg3\:justify-between {
    justify-content: space-between;
  }

  html {
    --bg-opacity: 1;
  }

  .lg3\:-right-32 {
    right: -8rem;
  }
}
@media (min-width: 1280px) {

  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:pl-2 {
    padding-left: 0.5rem;
  }

  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:mt-2 {
    margin-top: 0.5rem;
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }

  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:pt-15 {
    padding-top: 3.75rem;
  }

  .xl\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .xl\:col-span-1.xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-span-1.xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .xl\:col-span-3.xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-span-3.xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .xl\:col-span-4.xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-span-4.xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .xl\:col-span-8.xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-span-8.xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:gap-0 {
    gap: 0rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:text-5xl-h {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  .xl\:w-max {
    width: max-content;
  }

  .xl\:w-max-115 {
    max-width: 28.125rem;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:block {
    display: block;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  html {
    --bg-opacity: 1;
  }

  .xl\:bg-black-90 {
    background-color: rgba(28, 25, 38, var(--bg-opacity));
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:left-6 {
    left: 1.5rem;
  }
}
@media (min-width: 1366px) {

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 1536px) {

  .xl2\:col-span-5 {
    grid-column: span 5/span 5;
  }

  .xl2\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl2\:col-span-7 {
    grid-column: span 7/span 7;
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 1600px) {

  html {
    --bg-opacity: 1;
  }

  .xl2-5\:grid-cols-max-fr {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
  }
}
@media (min-width: 1920px) {

  .xl3\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl3\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 2200px) {

  .xl4\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl4\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 2500px) {

  .xl5\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl5\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  html {
    --bg-opacity: 1;
  }
}
@media (min-width: 3000px) {

  .xl6\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  html {
    --bg-opacity: 1;
  }
}

.rotate-z-90 {
  transform: rotateZ(90deg);
}

.rotate-z-180 {
  transform: rotateZ(180deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.overflow-auto {
  overflow-x: auto;
  overflow-y: auto;
}
.overflow-auto.overflow-y-auto {
  overflow-y: auto;
}
.overflow-auto.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-hidden {
  overflow-x: hidden;
  overflow-y: hidden;
}
.overflow-hidden.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-hidden.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.translate-x-20 {
  transform: translateX(5rem);
}

.-translate-x-20 {
  transform: translateX(-5rem);
}

.translate-x-40 {
  transform: translateX(10rem);
}

.translate-y-full {
  transform: translateY(100%);
}

.-translate-y-full {
  transform: translateY(-100%);
}

.-translate-x-25p {
  transform: translateX(-25%);
}

.translate-y-0 {
  transform: translateY(0, 0);
}

.place-self-end {
  place-self: end;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.blur-40 {
  backdrop-filter: blur(10rem);
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip > #arrow {
  z-index: -1;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

.tooltip {
  isolation: isolate;
  display: none;
}

.tooltip[data-show] {
  display: block;
  animation: fade-in ease-in-out 150ms forwards;
}

.relative {
  position: relative;
}

.max-w-891 {
  max-width: 891px;
}

.w-50p {
  width: 50%;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.flex-col {
  flex-direction: column;
}

.flex-reverse {
  flex-direction: column-reverse;
}

.bg-black {
  background: rgba(0, 0, 0, var(--bg-opacity));
}

.bg-white {
  background: rgba(255, 255, 255, var(--bg-opacity));
}

.text-white {
  color: #fff;
}

.border-white {
  border-color: #fff;
}

.bg-theme-dark-gray {
  background: rgba(42, 40, 51, var(--bg-opacity));
}

.bg-theme-black {
  background: rgba(22, 20, 32, var(--bg-opacity));
}

.text-theme-black {
  color: #161420;
}

.text-theme-gray-light {
  color: #abaaae;
}

.bg-theme-blue {
  background: rgba(50, 136, 236, var(--bg-opacity));
}

.text-theme-blue {
  color: #3288ec;
}

.bg-blue {
  background: #3288ec;
}

.bg-gray {
  background: #454252;
}

.border-theme-blue {
  border-color: #3288ec;
}

.border-theme-blue-light {
  border-color: #6c84b2;
}

.placeholder-theme-blue-light::placeholder {
  color: #6c84b2;
}

.bg-theme-lime {
  background: rgba(90, 255, 134, var(--bg-opacity));
}

.border-theme-lime {
  border-color: #5aff86;
}

.text-theme-secondary {
  color: #5c5c5c;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.-z-1 {
  z-index: -1;
}

.z-2 {
  z-index: 2;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.transition-all {
  transition: 150ms all ease;
}

.transition-all.duration-100 {
  transition-duration: 100ms;
}

.transition-all.duration-150 {
  transition-duration: 150ms;
}

.transition-all.duration-300 {
  transition-duration: 300ms;
}

.transition-all.duration-500 {
  transition-duration: 500ms;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-center {
  text-align: center;
}

.-right-full {
  right: -100%;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-55p {
  top: 55%;
}

.bottom-0 {
  bottom: 0;
}

.-top-1-5 {
  top: -0.375rem;
}

.bg-opacity-60 {
  --bg-opacity: 0.6;
}

.h-full {
  height: 100%;
}
@media (min-width: 768px) {
  .md\:pr-2 {
    padding-right: 0.5rem;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-base-dense {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:left-30p {
    left: 30%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:opacity-1 {
    opacity: 1;
  }

  .md\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .lg\:opacity-1 {
    opacity: 1;
  }

  .lg\:h-80vh {
    height: 80vh;
  }

  .lg\:left-35p {
    left: 35%;
  }

  .lg\:max-h-433px {
    max-height: 433px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-8-5 {
    margin-bottom: 2.125rem;
  }

  .lg\:pb-17 {
    padding-bottom: 4.25rem;
  }

  .lg\:pt-15 {
    padding-top: 3.75rem;
  }

  .lg\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .lg\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .lg\:pb-21-5 {
    padding-bottom: 5.375rem;
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:col-span-8 {
    grid-column: span 8/span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9/span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10/span 10;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-base-dense {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .lg\:text-5xl-h {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  .lg\:text-5xl-h-2 {
    font-size: 3rem;
    line-height: 3.75rem;
  }

  .lg\:text-3xl-d {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl-d {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-max {
    height: max-content;
  }

  .lg\:w-max {
    width: max-content;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:block {
    display: block;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:text-inherit {
    text-align: inherit;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }
}
@media (min-width: 1180px) {
  .lg3\:flex {
    display: flex;
  }

  .lg3\:block {
    display: block;
  }

  .lg3\:hidden {
    display: none;
  }
}
@media (min-width: 1280px) {
  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:max-w-1200 {
    max-width: 1200px;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:pt-15 {
    padding-top: 3.75rem;
  }

  .xl\:col-span-8 {
    grid-column: span 8/span 8;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .xl\:w-max {
    width: max-content;
  }

  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:items-center {
    align-items: center;
  }
}
@media (min-width: 1366px) {
  .xl1-5\:max-h-530 {
    max-height: 530px;
  }
}
#terraformer {
  font-size: 14px;
  position: relative;
  min-height: 100vh;
}

@property --num {
  syntax: "<integer>";
  initial-value: 25000000;
  inherits: false;
}
.counter {
  animation: counter 3s;
  counter-reset: num var(--num);
}

.counter::after {
  content: counter(num);
}

@keyframes counter {
  from {
    --num: 1000000;
  }
  to {
    --num: 25000000;
  }
}
#landing html,
#landing body {
  padding: 0;
  margin: 0;
  font-family: Poppins;
  background-color: #1c1926;
  color: #f6f6f6;
}
#landing a {
  text-decoration: none;
}
#landing * {
  box-sizing: border-box;
}
#landing .starfield {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
#landing .table-gradient {
  background: linear-gradient(359.98deg, #262433 9.99%, rgba(38, 36, 51, 0) 46.95%);
}
#landing .max-w-8xl {
  max-width: 1440px;
}
#landing .text-main-gray {
  color: #C2C2C2;
}
#landing .gap-x-3 {
  column-gap: 12px;
}
#landing .table-border {
  border-top: 1px solid #9C9C9C;
}
#landing .grid-cols-05fr-1fr-1fr-1fr-1fr-05fr {
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 0.5fr;
}
#landing .bg-table {
  background: #262433;
}
#landing .text-green {
  color: #5AC175;
}
#landing .py-6px {
  padding-top: 6px;
}
#landing .left-33p {
  left: 33%;
}
#landing .right-35p {
  right: 35%;
}
#landing .left-35p {
  left: 35%;
}
#landing .top-98p {
  top: 98%;
}
#landing .top-60p {
  top: 60%;
}
#landing .bg-roadmap-circle {
  background: #1F1D29;
}
#landing .text-roadmap-gray {
  color: #787196;
}
#landing .top-63p {
  top: 63%;
}
#landing .translate-50--50 {
  transform: translate(50%, -50%);
}
#landing .translate-50--0 {
  transform: translate(50%, 0%);
}
#landing .right-05 {
  right: 50%;
}
#landing .w-276px {
  width: 276px;
}
#landing .max-w-screen-lg {
  max-width: 1024px;
}
#landing .-bottom-22rem {
  bottom: -22rem;
}
#landing .-bottom-10rem {
  bottom: -10rem;
}

/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */
/*# sourceMappingURL=index.css.map */

/*# sourceMappingURL=index.css.map */
