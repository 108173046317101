@import "../styles/mixins";

.wrapper {
  width: 100vw;
  max-width: $modal-width;
}

.component {
  padding: 0;
}

.title {
  color: $blue;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 18px;
}

.button {
  @include flex();
  background-color: white;
  border: 1px solid $blue;
  border-radius: 10px;
  color: $deepgray;
  font-size: 18px;
  font-weight: 700;
  padding: 24px 24px;
  width: 100%;

  & + & {
    margin-top: 20px;
  }
}
