@import "../styles/mixins";

.article {
  text-align: center;
  background: #ffffff;
}

.card .card {
  background: #ffffff;
}

.desc {
  color: #0222ba;
  font-size: 16px;
  font-weight: 600;
  margin: 60px auto;
  white-space: pre-line;
}

.button:hover {
  background-color: #000000;
}

.installed {
  @include link;
  display: block;
  font-size: 18px;
  text-decoration: underline;
  color: #0222ba;
  margin: 5px auto 0;
  margin-top: 30px;
  margin-bottom: 20px;
}

.installed:hover {
  color: #4360e5;
}
