@import "../styles/mixins";
@import "../styles/variables";

.modal {
  width: max-content;
  outline: 0;
  position: relative;

  @include mobile {
    margin: 0 auto;
  }
}

.overlay {
  @include flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: fade-out(#070a13, 0.2);
  // z-index: 300;
}

.close {
  position: absolute;
  right: 25px;
  top: 25px;
  text-align: right;
  z-index: 1;

  span {
    cursor: pointer;
  }
}
