.aside {
  min-width: 457px;
}

.iconPosition {
  position: absolute;
  top: 14.5px;
  left: 18.5px;
}

.bgHotPairs {
  background: linear-gradient(229.89deg, rgba(87, 83, 109, 0.05) -23.86%, rgba(255, 255, 255, 0.05) 104.75%);
  backdrop-filter: blur(40px);
}

.bgGraph {
  background: linear-gradient(229.89deg, rgba(28, 25, 38, 0.05) -23.86%, rgba(28, 25, 38, 0.05) 104.75%);
}

.bgPool {
  background: linear-gradient(229.89deg, rgba(87, 83, 109, 0.05) -23.86%, rgba(255, 255, 255, 0.05) 104.75%);
}

.bgSwap {
  background: linear-gradient(229.89deg, rgba(87, 83, 109, 0.05) -23.86%, rgba(255, 255, 255, 0.05) 104.75%);
}

.markets {
  background-color: #262433;
  border-radius: 4px;
}

.searchInput {
  border: 1px solid #504e5f;
  border-radius: 4px;
  line-height: 38px;
  color: #504e5f;
}

.searchInputActive {
  border: 1px solid #3288ec;
  border-radius: 4px;
  line-height: 38px;
  color: #504e5f;
}

.contentLayoutTemplate {
}

.lineHeigth {
  line-height: 42px;
}

.hotPairsMargin {
}

.hotPairsMargin + .hotPairsMargin {
  margin-left: 16px;
}

.q > * {
}

.starBackgroundColor {
  background-color: #262433;
}

.socialMargin {
}

.socialMargin + .socialMargin {
  margin-left: 8px;
}

.poolCardMargin {
}

.poolCardMargin + .poolCardMargin {
  margin-top: 16px;
}

.cardTitle {
  line-height: 16px;
  font-size: 13px;
}

.redStatText {
  color: #fc5b5b;
  line-height: 16px;
  font-size: 13px;
}

.historiMargin {
}

.historiMargin + .historiMargin {
  margin-left: 24px;
}

.blueBorder {
  border-bottom: 2px solid #3288ec;
}

.nav {
  background-color: #161420;
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
}

.navLinks {
}

.boxShadow {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.1);
}

.tradeHistoryHeader {
  background-color: #161420;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.tradeHistoryButtons {
  background-color: #161420;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.proTradeCol {
  background-color: #696679;
}
